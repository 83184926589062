import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import { markdownConverter } from "../utils/markdown";
import { FaMapMarkerAlt } from "react-icons/fa";

const KauppapuistoPage = ({ data }) => {
  const remark = data.allMarkdownRemark.edges[0].node;
  const { frontmatter: pageData } = remark;
  console.log(data);
  return (
    <Layout title="Turun Kauppapuisto">
      <div className="page section">
        <div className="container kauppapuisto-page">
          <PageHeader
            title={pageData.pageTitle}
            description={pageData.pageDescription}
            image={pageData.pageImage}
            kauppapuisto
          />
          <div className="kauppapuisto-page__hero">
            <div className="columns kauppapuisto-page__hero__columns">
              <div className="column is-5 kauppapuisto-page__hero__columns__left">
                <h1>{pageData.pageTitle}</h1>
                <p>{pageData.pageDescription}</p>
                <p className="kauppapuisto-page__hero__columns__left__location">
                  <FaMapMarkerAlt /> {pageData.address}

                </p>
              </div>
              <div className="column is-7 kauppapuisto-page__hero__columns__right">
                <div
                  className="mb text-container"
                  dangerouslySetInnerHTML={{
                    __html: markdownConverter.makeHtml(pageData.pageContent1),
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "4px" }}>
            {pageData.images.map((image, index) => (
              <img
                className="fullwidth"
                key={image}
                src={image}
                alt={`kauppapuisto-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

KauppapuistoPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default KauppapuistoPage;

export const pageQuery = graphql`
  query KauppapuistoPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "kauppapuisto-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            pageTitle
            pageDescription
            pageImage
            images
            pageContent1
            address
          }
        }
      }
    }
  }
`;
